<template>
    <div v-if="images"
        class="owl-carousel"
        :id="['owl2-' + unitid]">
        <div v-for="img in images"
            class="item">
            <a :href="getUnitPath()"
                :target="getTarget()">
                <img-fallback :img="img.id"
                    mode="img"
                    img-format="m"
                    vo-msg:placeholder="no.pic.placeholder"></img-fallback>
            </a>

        </div>
    </div>
    <div v-else>
        <img-fallback mode="img"
            vo-msg:placeholder="no.pic.placeholder"></img-fallback>
    </div>
</template>

<script>
    import V from 'voUtils/V.js'



    export default {
    	voVueComponent: 'img-carousel2',
    	props: {
    		images: Array,
    		initDelay: {
    			type: Number,
    			default: 0
    		},
    		default: () => [{
    			id: -1,
    			width: 600,
    			height: 400,
    			name: '//placehold.it/450x300&text=No Image'
    		}],
    		unitid: Number,
    		unitpath: String

    	},

    	mounted: function() {
    		if (this.initDelay) {
    			setTimeout(() => {
    				this.init()
    			}, this.initDelay)
    		} else {
    			this.init()
    		}

    	},

    	methods: {
    		init() {
    				$("#owl2-" + this.unitid).owlCarousel({
    					items: 1,
    					loop: true,
    					center: false,
    					autoplay: false,
    					smartSpeed: 1000,
    					dots: true,
    					nav: true,
    					lazyLoad: true,
    				});
    			},
    			getTarget: function() {
    				return this.$root.getUnitSearchTarget();
    			},
    			getUnitPath: function() {
    				return this.$root.getUnitSearchPath(this.unitpath);
    			}
    	}

    };
</script>