<template>
    <a href="javascript:void(0);"
        data-toggle="popover"
        title="Sonderangebote"
        data-trigger="click"
        data-placement="top"
        data-html="true"
        :data-content="getOfferText">
        <span class="offer-left">Sonderangebote verfügbar</span>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'offer-help',
    	props: {
    		offers: Array
    	},
    	mounted: function() {
    		$('[data-toggle="tooltip"]').tooltip();
    		$('[data-toggle="popover"]').popover();
    		$('body').on('click', function(e) {
    			$('[data-toggle=popover]').each(function() {
    				if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
    					(($(this).popover('hide').data('bs.popover') || {}).inState || {}).click = false;
    				}
    			});
    		});
    	},
    	computed: {

    		getOfferText: function() {
    			var offerText = '';
    			if (this.offers) {
    				this.offers.sort((a, b) => (a.fromdate > b.fromdate) ? 1 : -1);
    				var i;
    				offerText = "<ul>";
    				for (i = 0; i < this.offers.length; i++) {
    					offerText += "<li>";
    					//console.log('Offer:::' + JSON.stringify(this.offers[i], 4));

    					if (this.offers[i].type == 'LASTMINUTE') {
    						offerText += "Last Minute Angebot: " + this.offers[i].value / 100 + "% ";
    						offerText += "bei Anreise innerhalb von " + this.offers[i].timeSpan + " Tagen";
    					} else {
    						offerText += this.offers[i].name + " "
    						offerText += "von " + DateEx.formatDate(this.offers[i].fromdate, undefined, VOFFICE.mlang) + " ";
    						offerText += "bis " + DateEx.formatDate(this.offers[i].tilldate, undefined, VOFFICE.mlang) + " ";
    					}
    				}
    			}

    			return offerText;
    		}
    	}
    };
</script>